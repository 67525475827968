/* Component Dependencies */
var marriottRewardsMemberRatesTemplate = require('templates/marriottRewardsMemberRates.hbs');
var marriottRewardsMemberRatesOfferNextGenTemplate = require('templates/marriottRewardsMemberRates-offerNextGen.hbs');

var AriesComponent = require('libs/aries-component');
var Constants = require('libs/constants');

AriesComponent.create({
  type: 'marriottRewardsMemberRates',
  template: {
	'marriottRewardsMemberRates': marriottRewardsMemberRatesTemplate,
	'marriottRewardsMemberRatesOfferNextGen': marriottRewardsMemberRatesOfferNextGenTemplate
  },
  bindEvents: function() {
  	var _self = this;
  	var viewport = Constants.GLOBAL;
  	$(window).on("resize",function() {
  		var windowWidth = $(window).width();
  		if (windowWidth <= viewport.MOBILE_VIEWPORT) {
  			_self.$el.closest('.t-box-shadow').removeClass('t-box-shadow').parent().addClass('t-box-shadow');
  		} else {
  			$(_self.$el.closest('.t-box-shadow').removeClass('t-box-shadow').children()[0]).addClass('t-box-shadow');
  		}
  	});
  }
});
